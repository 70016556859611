import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataApi } from '../../Model/dataApi';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeguridadService {

  constructor(private http: HttpClient) { }

  GetDataApi(url: string, params: any) {
    return this.http.get<DataApi>(environment.apiUrlSecurity + url + params);
  }

  PostDataApi(url: string, params: any) {
    return this.http.post<DataApi>(environment.apiUrlSecurity + url, params);
  }

  DeleteDataApi(url: string, params: any) {
    
    return this.http.delete<DataApi>(environment.apiUrlSecurity + url + params);
  }

  DeleteDataApiBody(url: string, params: any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
      body: params
    };

    return this.http.delete<DataApi>(environment.apiUrlSecurity + url, options);
  }

  PutDataApi(url: string, params: any) {
    return this.http.put<DataApi>(environment.apiUrlSecurity + url, params);
  }
}
